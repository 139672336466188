import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next-intl@3.17.2_next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_reac_nv5j2da45jczhjgfoyg7oqqcme/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next-intl@3.17.2_next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_reac_nv5j2da45jczhjgfoyg7oqqcme/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastRoot"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/components/Toasts/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletConnectButtonSkeleton","WalletConnectButtonDisabled"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/features/wallet-connect/_components/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkInternalWithPageReload"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/features/wallet-connect/_components/LinkWithPageRefresh.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletConnectLoginForm"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/features/wallet-connect/_components/LoginForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletConnectTab"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/features/wallet-connect/_components/Tab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletConnectUpdateForm"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/features/wallet-connect/_components/UpdateForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletConnectStateProvider","useWalletConnectState"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/features/wallet-connect/context/wallet-connect-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["reducer","WALLET_CONNECT_NAMESPACE"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/features/wallet-connect/context/wallet-connect-state.ts");
;
import(/* webpackMode: "eager", webpackExports: ["AccountMenu"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/layout/DashboardLayoutView/AccountDropdown.tsx.tsx");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/layout/DashboardLayoutView/index.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/layout/DashboardLayoutView/DashboardNav.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/layout/DashboardLayoutView/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoTopbar"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/layout/DashboardLayoutView/LogoTopbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNav"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/layout/DashboardLayoutView/MobileNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageLink"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/layout/DashboardLayoutView/PageLink.tsx");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/layout/DashboardLayoutView/Topbar.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/layout/DashboardLayoutView/Sidebar.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["InactivityLogoutWrapper"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/lib/auth/browser/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GqlProvider"] */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/lib/gql-client/browser/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-4/_work/io-platform-dashboard/io-platform-dashboard/src/lib/subscriptions/index.tsx");
