'use client';
import { useController, useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import { ResultOf } from '@graphql-typed-document-node/core';

import { FragmentType, getFragmentData } from '@/lib/gql';
import { Form } from '@/lib/io-kit/Form';
import { VaultOption, VaultSelect } from '@/components/VaultSelect';
import {
  WalletConnectFormFragment,
  WalletConnectFormAddressFragment,
  WalletConnectLoginFormInputs,
  FieldIds,
} from '@/features/wallet-connect/page-logic';

type Props = {
  isLoadingVaults: boolean;
  currentVault: ResultOf<typeof WalletConnectFormFragment>;
  vaults: ResultOf<typeof WalletConnectFormFragment>[];
  onLoadMoreVaults: () => void;
  onSearch: (inputValue: string) => Promise<ResultOf<typeof WalletConnectFormFragment>[]>;
  address?: FragmentType<typeof WalletConnectFormAddressFragment>;
};

export function VaultInfo({ currentVault, vaults, isLoadingVaults, onLoadMoreVaults, onSearch, address }: Props) {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  const { control } = useFormContext<WalletConnectLoginFormInputs>();
  const { field } = useController({ name: 'vaultId', control });

  const [vaultOptions, setVaultOptions] = useState<VaultOption[]>([]);

  const addressData = address ? getFragmentData(WalletConnectFormAddressFragment, address) : undefined;

  const createVaultOption = useCallback(
    (vault: ResultOf<typeof WalletConnectFormFragment>) => {
      const vaultAddressData = vault.details.visibleAssets.map((address) =>
        getFragmentData(WalletConnectFormAddressFragment, address),
      );

      const vaultAddressOnNetwork = vaultAddressData.find((address) => address.asset.id === addressData?.asset.id);

      return {
        value: vault.id,
        label: vault.details.name,
        threshold: vault.details.threshold,
        isSelected: vault.id === currentVault.id,
        address: vaultAddressOnNetwork
          ? {
              id: vaultAddressOnNetwork.id,
              balanceUsd: vault.details.balanceUsd ?? '0',
              addressHash: vaultAddressOnNetwork.addressHash,
              balanceAsCoin: vaultAddressOnNetwork.balanceAsCoin,
              assetId: vaultAddressOnNetwork.asset.id,
            }
          : undefined,
      };
    },
    [addressData, currentVault.id],
  );

  const generateVaultOptions = useCallback(
    (vaultsList: ResultOf<typeof WalletConnectFormFragment>[]) => {
      return vaultsList.map((vault) => createVaultOption(vault));
    },
    [createVaultOption],
  );

  useEffect(() => {
    const options = generateVaultOptions(vaults);
    setVaultOptions(options);
  }, [vaults, generateVaultOptions]);

  const handleSelectOption = useCallback(
    (newValue: unknown) => {
      const { value } = newValue as VaultOption;
      field.onChange(value);
    },
    [field],
  );

  const loadOptions = useCallback(
    (inputValue: string, resolve: (options: VaultOption[]) => void) => {
      onSearch(inputValue).then((searchedVaults) => {
        const options = generateVaultOptions(searchedVaults);
        setVaultOptions(options);
        resolve(options);
      });
    },
    [onSearch, generateVaultOptions],
  );

  const defaultVaultOption = useMemo(() => {
    return vaultOptions.find((vault) => vault.isSelected);
  }, [vaultOptions]);

  return (
    <Form.Group>
      <Form.Label>{t('vaultLabel')}</Form.Label>
      <VaultSelect
        defaultValue={defaultVaultOption}
        inputId={FieldIds.Vault}
        id="wallet-connect-vault"
        data-testid="wallet-connect.form.vault"
        name={field.name}
        onChange={handleSelectOption}
        defaultOptions={vaultOptions}
        value={vaultOptions.find((vault) => vault.value === field.value)}
        openMenuOnFocus={false}
        onMenuScrollToBottom={onLoadMoreVaults}
        loadOptions={loadOptions}
        isLoading={isLoadingVaults}
      />
    </Form.Group>
  );
}
