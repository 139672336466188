'use client';

import { useTranslations } from 'next-intl';
import { ResultOf } from '@graphql-typed-document-node/core';

import { FragmentType, getFragmentData } from '@/lib/gql';
import { CryptoAddressOrId } from '@/components/CryptoAddressOrId';
import { Jazzicon } from '@/lib/io-kit/Jazzicon';
import { LoadingButton } from '@/lib/io-kit/Button';
import { WalletConnectFormFragment, WalletConnectFormAddressFragment } from '@/features/wallet-connect/page-logic';

import styles from './VaultInfoDisplay.module.scss';

type Props = {
  vault: ResultOf<typeof WalletConnectFormFragment>;
  address?: FragmentType<typeof WalletConnectFormAddressFragment>;
  isLoading: boolean;
  onClick: () => void;
};

export function VaultInfoWithAction({ vault, address, isLoading, onClick }: Props) {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  const addressData = address ? getFragmentData(WalletConnectFormAddressFragment, address) : undefined;

  return (
    <div className={styles.vaultInfo}>
      <div className={styles.vaultHeader}>
        <Vault
          data={{
            value: vault.id,
            label: vault.details.name,
            threshold: vault.details.threshold,
            address: addressData,
          }}
        />
        <LoadingButton variant="dark" onClick={onClick} loading={isLoading}>
          {t('change')}
        </LoadingButton>
      </div>
    </div>
  );
}

function Vault({
  data,
}: {
  data: { value: string; label: string; threshold: number; address?: { addressHash: string } };
}) {
  return (
    <div className={styles.wrapper}>
      <Jazzicon.Vault size="huge" vaultId={data.value} threshold={data.threshold} />
      <div className={styles.info}>
        <div className={styles.vaultName} title={data.label}>
          {data.label}
        </div>
        {data.address && (
          <div className={styles.walletAndBalance}>
            <CryptoAddressOrId value={data.address.addressHash} showFullValue />
          </div>
        )}
      </div>
    </div>
  );
}
