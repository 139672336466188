import { EIP155Chain, EIP155_CHAINS, EIP155_NAMESPACE } from '@/lib/web3-access';
import { Network } from '@/lib/models';
import { State } from '@/features/dapp-iframe/context';

const ethereumChain = EIP155_CHAINS[`${EIP155_NAMESPACE}:${Network.getChainId(Network.Type.Ethereum)}`];

export const DAPP_NAMESPACE = 'DAPP__';

export const defaultCurrentChain: EIP155Chain = ethereumChain;
export const defaultConfigs: EIP155Chain[] = [];

export const initialState: State = {
  isLoading: true,
  settings: {
    currentChain: ethereumChain,
    user: null,
    address: '',
    vaultId: '',
    dAppInfo: undefined,
  },
  chains: Object.values(EIP155_CHAINS),
  modal: {
    isOpen: false,
    view: undefined,
    data: undefined,
  },
};

export const getInitialState = async (network?: Network.Type | null): Promise<State> => {
  const selectedChain = network ? EIP155_CHAINS[`${EIP155_NAMESPACE}:${Network.getChainId(network)}`] : ethereumChain;

  return {
    ...initialState,
    settings: {
      ...initialState.settings,
      currentChain: selectedChain,
    },
  };
};
