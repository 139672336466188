'use client';

import { useTranslations } from 'next-intl';
// Gotta use this nested import because it's the only way to get the `isRedirectError` function
import { isRedirectError } from 'next/dist/client/components/redirect';

import { Alert } from '@/lib/io-kit/Alert';
import { LinkTo } from '@/lib/links';
import { LinkInternalWithPageReload } from '@/features/wallet-connect';

type Props = {
  error: Error;
};

export function ErrorLoadingVaults({ error }: Props) {
  const t = useTranslations('Components.WalletConnect.ErrorLoadingVaults');

  const message = t.rich('loadingError', {
    try: (chunk) => <LinkInternalWithPageReload href={LinkTo.vaultList()}>{chunk}</LinkInternalWithPageReload>,
  });

  return <ErrorAlertWithDebugTrace alertMessage={message} error={error} data-testid="all-vaults.errors.vaults-list" />;
}

function ErrorAlertWithDebugTrace({
  error,
  alertMessage,
  'data-testid': testId,
}: Props & { alertMessage: React.ReactNode; 'data-testid'?: string }) {
  if (isRedirectError(error)) throw error;

  // This is intended, done in order to report this to Sentry
  if (error) console.error(error);

  return <Alert variant="error" title={alertMessage} data-testid={testId} />;
}
