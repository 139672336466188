export const prefixedAddressRe = /[\da-z-]+:0x[\da-f]{40}/i;

const invalidProtocolRegex = /^(\W*)(javascript|data|vbscript)/im;
// eslint-disable-next-line no-control-regex
const ctrlCharactersRegex = /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
const urlSchemeRegex = /^([^:]+):/gm;
const relativeFirstCharacters = new Set(['.', '/']);

/**
 * Format a URL for display, showing protocol and domain (including subdomain)
 */
export const formatUrlForDisplay = (url: string): string => {
  if (!url) return '';

  try {
    const parsedUrl = new URL(url);
    return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
  } catch {
    console.error('Invalid URL:', url);
    return '';
  }
};

/**
 * Convert the app id to a valid URL using base64 encoding
 * @param id - The app id to convert
 * @returns The app URL
 */
export const convertIdToUrl = (id: string): string => {
  // Decode the base64 string
  const decodedUrl = Buffer.from(id, 'base64').toString('utf8');

  // Will throw if appUrl is not a valid URL
  new URL(decodedUrl);

  return decodedUrl;
};
/**
 * Convert the app URL to a valid id using base64 encoding
 * @param url - The app URL to convert
 * @returns The app id
 */
export function convertUrlToId(url: string): string {
  // Parse the URL
  const parsedUrl = new URL(url);

  // Remove trailing slash if present
  const normalizedUrl = parsedUrl.href.replace(/\/$/, '');

  // Convert the URL to base64
  return Buffer.from(normalizedUrl).toString('base64');
}

/**
 * Remove the trailing slash from the URL
 */
export const trimTrailingSlash = (url: string): string => {
  return url.replace(/\/$/, '');
};

/**
 * Check if two URLs are the same
 */
export const isSameUrl = (url1: string, url2: string): boolean => {
  return trimTrailingSlash(url1) === trimTrailingSlash(url2);
};

/**
 * Check if the URL is relative
 */
export const isRelativeUrl = (url: string): boolean => {
  return relativeFirstCharacters.has(url[0]);
};

/**
 * Sanitize the URL by removing control characters and ensuring it's not relative
 */
export const sanitizeUrl = (url: string): string => {
  const sanitizedUrl = url.replaceAll(ctrlCharactersRegex, '').trim();

  if (isRelativeUrl(sanitizedUrl)) {
    return sanitizedUrl;
  }

  const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);
  if (!urlSchemeParseResults) {
    return sanitizedUrl;
  }

  const urlScheme = urlSchemeParseResults[0];
  if (invalidProtocolRegex.test(urlScheme)) {
    throw new Error('Invalid protocol');
  }

  return sanitizedUrl;
};

/**
 * Get the origin from the URL
 */
export const getOrigin = (url?: string): string => {
  if (!url) return '';

  const { origin } = new URL(url);

  return origin;
};

/**
 * Get the host from the URL
 */
export const getHost = (url?: string): string => {
  if (!url) return '';

  const { host } = new URL(url);

  return host;
};
