'use client';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useSearchParams } from 'next/navigation';
import debounce from 'lodash.debounce';

import { Sheet, SheetContent, SheetTrigger } from '@/components/Sheet';
import { Icons } from '@/lib/io-kit/Icons';
import { IconAsset } from '@/lib/io-kit/IconAssets';
import { getFragmentData, graphql } from '@/lib/gql';
import { LinkExternal } from '@/components/Link';
import { useFormAlert } from '@/lib/hooks/browser';
import { Alert } from '@/lib/io-kit/Alert';
import { showDismissableAlertToast } from '@/components/Toasts';
import {
  WalletConnectFormAddressFragment,
  WalletConnectFormFragment,
  ErrorLoadingVaults,
} from '@/features/wallet-connect/page-logic';
import { useWalletConnectState } from '@/features/wallet-connect/context';
import { WALLET_CONNECT_WEBSITE, defaultWalletConnectSettings } from '@/features/wallet-connect/utils';
import { gqlClient } from '@/lib/gql-client/browser';
import { Asset } from '@/lib/models';
import { unsupportedWeb3AccessNetworks } from '@/lib/web3-access';
import { globalLogger } from '@/lib/logger';

import { handleDisconnectActiveSessions } from '../services';

import styles from './Tab.module.scss';
import { WalletConnectButton, WalletConnectButtonDisabled, WalletConnectButtonSkeleton } from './Button';
import { WalletConnectLoginForm } from './LoginForm';
import { WalletConnectUpdateForm } from './UpdateForm';

const VAULTS_AMOUNT = 30;
const DEBOUNCE_INTERVAL = 350;

const fetchVaultByIdQuery = graphql(`
  query fetchVaultByIdWalletConnect($vaultId: ID!) {
    vault_v2(vaultId: $vaultId) {
      id
      ...WalletConnectForm
    }
  }
`);

const fetchAvailableVaultsQuery = graphql(`
  query fetchAvailableVaultsWalletConnect($name: String, $first: Int, $after: String) {
    vaults_v2(first: $first, after: $after, where: { name: $name, status: [CREATED] }) {
      edges {
        node {
          id
          ...WalletConnectForm
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const logger = globalLogger.child({ scope: 'wallet-connect' });

export function WalletConnectTab() {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');
  const tToast = useTranslations('Toasts.WalletConnectToasts.SessionDisconnected');

  // State management
  const [currentVault, setCurrentVault] = useState<ResultOf<typeof WalletConnectFormFragment> | null>(null);
  const [vaults, setVaults] = useState<ResultOf<typeof WalletConnectFormFragment>[]>([]);
  const [afterCursor, setAfterCursor] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState<Error | null>(null);

  // Loading states
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isFetchingVaults, setIsFetchingVaults] = useState(false);
  const isInitialFetchInProgress = useRef(false);
  const hasFetchedCurrentVault = useRef(false);

  // External state
  const { sidebar, settings, web3Wallet, mutations } = useWalletConnectState();
  const { dismiss: dismissSuccess, showAlert: isSuccessAlertEnabled } = useFormAlert(successMessage);
  const wasUserConnected = useRef(settings.isUserConnected);

  // URL params
  const searchParams = useSearchParams();
  const vaultIdFromParams = searchParams.get('wcVaultId');
  const wcCodeFromParams = searchParams.get('wcCode');
  // const addressIdFromParams = searchParams.get('address');
  const initialVaultIdRef = useRef(wcCodeFromParams);

  // Vault fetching logic
  const fetchInitialVaults = useCallback(async () => {
    if (isInitialFetchInProgress.current) return;

    isInitialFetchInProgress.current = true;
    setIsInitialLoading(true);

    try {
      const availableVaultsResult = await gqlClient.request(fetchAvailableVaultsQuery, {
        first: VAULTS_AMOUNT,
      });

      const fetchedVaults = availableVaultsResult.vaults_v2.edges.map((edge) =>
        getFragmentData(WalletConnectFormFragment, edge.node),
      );
      setAfterCursor(availableVaultsResult.vaults_v2.pageInfo.endCursor ?? null);

      if (fetchedVaults.length === 0) return;

      setVaults(fetchedVaults);

      const firstValidVault = fetchedVaults.find((vault) => filterAddresses(vault).length > 0);
      if (!firstValidVault) return;

      setCurrentVault(firstValidVault);
    } catch (err) {
      logger.error('Error fetching initial vaults:', err);
      setError(err as Error);
    } finally {
      setIsInitialLoading(false);
      isInitialFetchInProgress.current = false;
    }
  }, []);

  const forceDisconnect = useCallback(async () => {
    await handleDisconnectActiveSessions(web3Wallet);
    mutations.setSettings(defaultWalletConnectSettings);
    mutations.setIsUserDisconnect(true);
    wasUserConnected.current = false;
  }, [mutations, web3Wallet]);

  const updateSelectedVault = useCallback(
    async (vaultId: string) => {
      setIsFetchingVaults(true);
      try {
        const result = await gqlClient.request(fetchVaultByIdQuery, { vaultId });
        if (result.vault_v2) {
          const vaultData = getFragmentData(WalletConnectFormFragment, result.vault_v2);

          setVaults((prevVaults) => {
            if (!prevVaults.some((v) => v.id === vaultData.id)) {
              return [vaultData, ...prevVaults];
            }
            return prevVaults;
          });
          setCurrentVault(vaultData);
        }
      } catch (err) {
        logger.error('Error fetching vault by id:', err);
        // If current vault is not found, force disconnect active session
        forceDisconnect();
      } finally {
        setIsFetchingVaults(false);
      }
    },
    [forceDisconnect],
  );

  const fetchMoreVaults = useCallback(async () => {
    if (!afterCursor) return;
    setIsFetchingVaults(true);
    try {
      const result = await gqlClient.request(fetchAvailableVaultsQuery, {
        first: VAULTS_AMOUNT,
        after: afterCursor,
      });
      const newVaults = result.vaults_v2.edges.map((edge) => getFragmentData(WalletConnectFormFragment, edge.node));
      setVaults((prevVaults) => {
        const combinedVaults = [...prevVaults, ...newVaults];
        return [...new Map(combinedVaults.map((vault) => [vault.id, vault])).values()];
      });
      setAfterCursor(result.vaults_v2.pageInfo.endCursor ?? null);
    } catch (err) {
      logger.error('Error fetching more vaults:', err);
      setError(err as Error);
    } finally {
      setIsFetchingVaults(false);
    }
  }, [afterCursor]);

  // Search functionality
  const debouncedFetchVaults = useMemo(
    () =>
      debounce(
        async (inputValue: string, resolve: (options: ResultOf<typeof WalletConnectFormFragment>[]) => void) => {
          if (!inputValue.trim()) {
            resolve(vaults);
            return;
          }
          setIsFetchingVaults(true);

          try {
            const result = await gqlClient.request(fetchAvailableVaultsQuery, {
              name: inputValue,
              first: VAULTS_AMOUNT,
            });

            const searchedVaults = result.vaults_v2.edges.map((edge) =>
              getFragmentData(WalletConnectFormFragment, edge.node),
            );

            if (searchedVaults) {
              const uniqueVaults = searchedVaults.filter(
                (newVault) => !vaults.some((existingVault) => existingVault.id === newVault.id),
              );
              setVaults((prev) => [...prev, ...uniqueVaults]);
              resolve(searchedVaults);
            } else {
              resolve([]);
            }
          } catch (err) {
            logger.error('Error searching vaults:', err);
            resolve([]);
          } finally {
            setIsFetchingVaults(false);
          }
        },
        DEBOUNCE_INTERVAL,
        { trailing: true },
      ),
    [vaults],
  );

  const searchVaults = useCallback(
    (inputValue: string) =>
      new Promise<ResultOf<typeof WalletConnectFormFragment>[]>((resolve) => {
        debouncedFetchVaults(inputValue, resolve);
      }),
    [debouncedFetchVaults],
  );

  // Initial fetch
  useEffect(() => {
    fetchInitialVaults();
  }, [fetchInitialVaults]);

  useEffect(() => {
    if (vaultIdFromParams === null) return;
    if (hasFetchedCurrentVault.current && initialVaultIdRef.current === vaultIdFromParams) return;

    hasFetchedCurrentVault.current = true;
    initialVaultIdRef.current = vaultIdFromParams;
    updateSelectedVault(vaultIdFromParams);
  }, [vaultIdFromParams, updateSelectedVault]);

  useEffect(() => {
    if (wasUserConnected.current && !settings.isUserConnected) {
      if (settings.isUserDisconnect) {
        setSuccessMessage(t('disconnected'));
        mutations.setIsUserDisconnect(false);
      } else {
        showDismissableAlertToast(tToast('title'), tToast('description'), 'error');
      }
    }
    wasUserConnected.current = settings.isUserConnected;
  }, [mutations, settings.isUserConnected, settings.isUserDisconnect, t, tToast]);

  useEffect(() => {
    if (settings.isUserConnected) {
      dismissSuccess();
      setSuccessMessage('');
    }
  }, [dismissSuccess, settings.isUserConnected]);

  // Early returns
  if (isInitialLoading) return <WalletConnectButtonSkeleton />;
  if (error) return <ErrorLoadingVaults error={error} />;
  if (!currentVault || vaults.length === 0) return <WalletConnectButtonDisabled />;

  // Prepare form data
  const filteredAddresses = filterAddresses(currentVault);
  const processedAddresses = filteredAddresses.map((address) =>
    getFragmentData(WalletConnectFormAddressFragment, address),
  );

  const networks = getNetworksFromAddresses(processedAddresses);

  const formProps = {
    isLoadingVaults: isFetchingVaults,
    currentVault,
    vaults,
    onLoadMoreVaults: fetchMoreVaults,
    onSearch: searchVaults,
    addresses: filteredAddresses,
    defaultValues: {
      address: filteredAddresses[0],
      wcCode: wcCodeFromParams ?? '',
    },
  };

  return (
    <Sheet onOpenChange={mutations.toggleSidebar} open={sidebar.isOpen} modal={true}>
      <SheetTrigger asChild>
        <Fragment>
          <WalletConnectButton />
        </Fragment>
      </SheetTrigger>
      <SheetContent side="right">
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.iconWrapper}>
              <IconAsset.WalletConnect className={styles.icon} />
            </div>
            <div className={styles.headerText}>
              <div className={styles.title}>{t('title')}</div>
              <LinkExternal href={WALLET_CONNECT_WEBSITE} className={styles.link} variant="on-hover">
                <Icons.ExternalLinkRounded className={styles.linkIcon} />
                <span> {t('website')}</span>
              </LinkExternal>
            </div>
          </div>
          <div className={styles.description}>{t('description')}</div>
        </div>
        {settings.isUserConnected ? (
          <WalletConnectUpdateForm key={currentVault.id} {...formProps} networks={networks} />
        ) : (
          <WalletConnectLoginForm key={currentVault.id} {...formProps} />
        )}
        {isSuccessAlertEnabled && successMessage && (
          <div className={styles.alertWrapper}>
            <Alert
              title={successMessage}
              variant="success"
              onDismiss={() => {
                dismissSuccess();
                setSuccessMessage('');
              }}
            />
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
}

// Helper functions
function filterAddresses(vault: ResultOf<typeof WalletConnectFormFragment>) {
  return vault.details.visibleAssets.filter((address) => {
    const addressData = getFragmentData(WalletConnectFormAddressFragment, address);
    const network = Asset.getNetwork(addressData.asset);
    return network && !unsupportedWeb3AccessNetworks.has(network);
  });
}

function getNetworksFromAddresses(addresses: ResultOf<typeof WalletConnectFormAddressFragment>[]) {
  return [
    ...new Set(
      addresses.map((address) => Asset.getNetwork(address.asset)).filter((it): it is NonNullable<typeof it> => !!it),
    ),
  ];
}
