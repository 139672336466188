import { z } from 'zod';

import { SimulationMethod } from '@/lib/gql/graphql';

export type FormType = z.infer<typeof FormSchema>;
export const FormSchema = z.object({
  accountAddress: z.string(),
  chainId: z.string(),
  method: z.nativeEnum(SimulationMethod),
  params: z.string(),
  domain: z.string(),
});

export type ApiType = z.infer<typeof ApiSchema>;
export const ApiSchema = FormSchema;
